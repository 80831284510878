// src/App.js

import React, { useEffect, useState } from 'react';
import NewReservation from './Pages/Form/index.js';
import InitPage from './Pages/Loading/InitPage.js';

function App() {
  const params = new URLSearchParams(window.location.search);
  const loadingParam = params.get('loading') !== 'false'; // Check if loading param is not explicitly set to 'false'
  
  const [showInitPage, setShowInitPage] = useState(loadingParam);

  window.baseDomain = "https://squid-app-aychi.ondigitalocean.app/";

  window.defaultViewMode = "full-screen";

  const viewMode = params.get('viewMode') || window.defaultViewMode;
  const restaurantName = params.get('restaurantName') || '';
  const restaurantId = params.get("restaurantId") || 'demo';
  window.viewMode = viewMode;
  window.restaurantName = restaurantName;
  window.restaurantId = restaurantId;
  console.log(`View Mode set to: ${viewMode}`);

  const handleInitComplete = () => {
    setShowInitPage(false);
  };

  return (
    <>
      {showInitPage && <InitPage onInitComplete={handleInitComplete} />}
      <NewReservation />
    </>
  );
}

export default App;
