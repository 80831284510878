// src/components/NewReservation/ReservationModal.jsx

import React, { useState, useEffect } from 'react';
import { FaXmark } from 'react-icons/fa6'; 
import reserveIcon from '../../../Assets/logos/logo-white.webp';
import './css/popup.css';

const ReservationModal = ({ isModalOpen, isClosing, openModal, closeModal, formContent }) => {
  const [previousScrollPosition, setPreviousScrollPosition] = useState(0);

  useEffect(() => {
    // When modal fully opens (isModalOpen = true and isClosing = false):
    // 1. Store the current scroll position
    // 2. Scroll to top
    if (isModalOpen && !isClosing) {
      setPreviousScrollPosition(window.scrollY);
      window.scrollTo(0, 0);
    }

    // When modal fully closes (isModalOpen = false and isClosing = false):
    // Restore the scroll position
    if (!isModalOpen && !isClosing) {
      window.scrollTo(0, previousScrollPosition);
    }
  }, [isModalOpen, isClosing, previousScrollPosition]);

  const handleOpenModal = () => {
    // Trigger the open modal function from parent
    openModal();
  };

  const handleCloseModal = () => {
    // Trigger the close modal function from parent
    closeModal();
  };

  return (
    <>
      <button className="open-modal-button" onClick={handleOpenModal}>
        <span className="button-content">
          <span className="button-text">Boek een Tafel</span>
          <div className="separator"></div>
          <img src={reserveIcon} alt="Reserve Icon" className="button-icon" />
        </span>
      </button>
      {(isModalOpen || isClosing) && (
        <>
          <div
            className={`modal-overlay ${isClosing ? 'hide' : 'show'}`}
            onClick={handleCloseModal}
          ></div>
          <div className={`modal ${isClosing ? 'hide' : 'show'}`}>
            <div className="modal-content">
              <button className="close-modal-button" onClick={handleCloseModal}>
                <FaXmark />
              </button>
              {formContent}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ReservationModal;
