import React, { useState, useEffect, useMemo } from 'react'; // useMemo
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup'; // Yup
import "./css/stepTwo.css";
import formatDateToDutch from './Utils/formatDateToDutch';
import useFetchFields from './Hooks/useFetchFields';
import moment from 'moment';
import 'moment/locale/nl';
import useApi from '../../../Hooks/useApi'; // Import useApi

const StepTwo = ({
  title,
  formData,
  handleChange,
  setCurrentStep,
  menu = [],
}) => {
  const { fields, loadingFields } = useFetchFields();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [availableMenus, setAvailableMenus] = useState([]);
  const [showNotification, setShowNotification] = useState(false);

  const { post } = useApi(); // Initialize useApi to use the post method

  const validationSchema = useMemo(() => {
    const schemaFields = {
      restaurantId: Yup.string().required('Restaurant ID is verplicht.'),
      date: Yup.string().required('Datum is verplicht.'),
      time: Yup.string().required('Tijd is verplicht.'),
      guests: Yup.number()
        .typeError('Aantal personen moet een nummer zijn.')
        .integer('Aantal personen moet een geheel getal zijn.')
        .min(1, 'Minimaal 1 persoon vereist.')
        .required('Aantal personen is verplicht.'),
      firstName: Yup.string().required('Voornaam is verplicht.'),
      lastName: Yup.string().required('Achternaam is verplicht.'),
      email: Yup.string().email('Voer een geldig e-mailadres in.').required('E-mailadres is verplicht.'),
      phone: Yup.string().required('Mobiel nummer is verplicht.'),
      extraInfo: Yup.string().notRequired(),
    };

    if (availableMenus.length > 0) {
      schemaFields.menu = Yup.string().required('Menu is verplicht.');
    } else {
      schemaFields.menu = Yup.string().notRequired();
    }

    return Yup.object().shape(schemaFields);
  }, [availableMenus]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...formData,
      restaurantId: window.restaurantId || '',
      date: window.selectedDate,
      menu: formData.menu || '',
    },
  });

  const watchedValues = watch();
  const formattedDate = formatDateToDutch(watchedValues.date);

  useEffect(() => {
    moment.locale('nl');
    if (watchedValues.date && watchedValues.time && menu.length > 0) {
      const selectedDate = watchedValues.date;
      const selectedTime = watchedValues.time;
      const selectedDateTime = moment(`${selectedDate} ${selectedTime}`, 'YYYY-MM-DD HH:mm');

      const filteredMenus = menu.filter((menuItem) => {
        const menuStartDate = moment(menuItem.startDate, 'YYYY-MM-DD');
        const menuEndDate = moment(menuItem.endDate, 'YYYY-MM-DD');
        const isDateInRange = selectedDateTime.isBetween(menuStartDate, menuEndDate, 'day', '[]');

        const menuStartTime = moment(menuItem.startHour, 'HH:mm');
        const menuEndTime = moment(menuItem.endHour, 'HH:mm');
        const selectedTimeMoment = moment(selectedTime, 'HH:mm');
        const isTimeInRange = selectedTimeMoment.isBetween(menuStartTime, menuEndTime, 'minute', '[]');

        const selectedDayOfWeek = selectedDateTime.format('dddd').toLowerCase();
        const daysOfWeek = menuItem.daysOfWeek.map((day) => day.toLowerCase());
        const isDayMatching = daysOfWeek.length === 0 || daysOfWeek.includes(selectedDayOfWeek);

        return isDateInRange && isTimeInRange && isDayMatching;
      });

      setAvailableMenus(filteredMenus);
    } else {
      setAvailableMenus([]);
    }
  }, [watchedValues.date, watchedValues.time, menu]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setShowNotification(false); // Hide notification before attempting to submit again

    const submissionData = {
      ...data,
      date: window.selectedDate,
      guests: Number(data.guests),
    };

    console.log("Submitted date: " + window.selectedDate);

    try {
      const url = `${window.baseDomain}api/reservations`;
      const response = await post(url, submissionData);

      // If response is successful, go to step 3
      setCurrentStep(3);
    } catch (error) {
      console.error('Submission failed:', error);
      // Show notification when submission fails
      setShowNotification(true);
    }

    try {
      localStorage.setItem('summary', JSON.stringify(submissionData));
    } catch (storageError) {
      console.error('Error saving summary to localStorage:', storageError);
    }

    setIsSubmitting(false);
  };

  const onError = (errors) => {
    console.error('Form submission errors:', errors);
  };

  if (loadingFields) {
    return <div>Loading fields...</div>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <h2 className="title default-text-color">{title}</h2>
      <h3 className="subtitle default-text-color">Stap 2/3</h3>

      {/* Show the notification only if the POST request failed */}
      {showNotification && (
        <div style={{
          backgroundColor: '#fff4e0',
          color: '#d35400',
          border: '1px solid #d35400',
          padding: '15px',
          marginBottom: '20px',
          marginTop: '20px',
          borderRadius: '5px',
          fontSize: '16px',
          lineHeight: '1.5'
        }}>
          De geselecteerde datum is niet meer geldig. Gelieve een nieuwe datum te kiezen.
        </div>
      )}

      <div className="flex-container">
        <div className="bubble-style" onClick={() => setCurrentStep(1, 'guest')}>
          {watchedValues.guests} Gasten
        </div>
        <div className="bubble-style" onClick={() => setCurrentStep(1, 'date')}>
          {formattedDate}
        </div>
        <div className="bubble-style" onClick={() => setCurrentStep(1, 'time')}>
          {watchedValues.time}
        </div>
      </div>

      {availableMenus.length > 0 && (
        <div className="form-group">
          <label className="default-text-color" htmlFor="menu">
            Selecteer Menu
            <span className="required">*</span>
          </label>
          <select
            id="menu"
            {...register('menu')}
            className={`form-control ${errors.menu ? 'is-invalid' : ''}`}
            onBlur={() => {
              trigger('menu');
            }}
          >
            <option value="">-- Kies een menu --</option>
            {availableMenus.map((menuItem) => (
              <option key={menuItem._id.$oid || menuItem._id} value={menuItem.name}>
                {menuItem.name}
              </option>
            ))}
          </select>
          {errors.menu && <span className="error-message">{errors.menu.message}</span>}
        </div>
      )}

      {fields.map((field) => (
        <div className="form-group" key={field.id}>
          <label className="default-text-color" htmlFor={field.id}>
            {field.label}
            {field.required && <span className="required">*</span>}
          </label>
          {field.id === 'date' || field.id === 'time' || field.id === 'guests' ? (
            null
          ) : field.type === 'textarea' ? (
            <textarea
              id={field.id}
              {...register(field.id)}
              placeholder={field.placeholder || ''}
              className={`form-control ${errors[field.id] ? 'is-invalid' : ''}`}
              onBlur={() => {
                trigger(field.id);
              }}
            ></textarea>
          ) : (
            <input
              type={field.type === 'input' ? 'text' : field.type}
              id={field.id}
              {...register(field.id)}
              placeholder={field.placeholder || ''}
              className={`form-control ${errors[field.id] ? 'is-invalid' : ''}`}
              onBlur={() => {
                trigger(field.id);
              }}
            />
          )}
          {errors[field.id] && <span className="error-message">{errors[field.id].message}</span>}
        </div>
      ))}

      <button type="submit" className="submit-button" disabled={isSubmitting}>
        {isSubmitting ? 'Reserveren...' : 'Reserveren'}
      </button>
    </form>
  );
};

export default StepTwo;
