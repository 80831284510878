import React, { useState, useEffect, useRef } from 'react';
import { FaCheckCircle, FaEnvelope } from 'react-icons/fa';
import './css/successPage.css';
import PrintButton from './Buttons/PrintButton';
import ShareButton from './Buttons/ShareButton';
import AddToCalendarButton from './Buttons/AddToCalendarButton';

const SuccessMessage = () => {
  const [summaryData, setSummaryData] = useState(null);
  const summaryRef = useRef(null);

  useEffect(() => {
    const data = localStorage.getItem('summary');
    if (data) {
      const parsedData = JSON.parse(data);
      setSummaryData(parsedData);
    }
  }, []);

  const formatDateDutch = (isoDateString) => {
    try {
      const date = new Date(isoDateString);
      return new Intl.DateTimeFormat('nl-NL', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }).format(date);
    } catch (error) {
      console.error('Invalid date format:', isoDateString);
      return isoDateString;
    }
  };

  return (
    <div className="success-page">
      <div className="email-message">
        <FaEnvelope className="success-icon" />
        <p>Uw reservatie werd genoteerd! Uw reservatie is bevestigd! Controleer uw inbox en map voor ongewenste email voor een bevestiging.</p>
      </div>

      {summaryData && (
        <div className="reservation-summary" ref={summaryRef}>
          <h2 className="title default-text-color title-bold">Uw Reservatie</h2>
          <ul>
            <li>
              <strong className="title default-text-color">Naam:</strong> {summaryData.firstName + ' ' + summaryData.lastName || ''}
            </li>
            <li>
              <strong className="title default-text-color">Datum:</strong> {formatDateDutch(summaryData.date) || ''}
            </li>
            <li>
              <strong className="title default-text-color">Tijd:</strong> {summaryData.time || ''}
            </li>
            <li>
              <strong className="title default-text-color">Aantal Personen:</strong> {summaryData.guests || ''}
            </li>
            {summaryData.menu && summaryData.menu.trim() && (
              <li>
                <strong className="title default-text-color">Menu:</strong> {summaryData.menu}
              </li>
            )}
            <li>
              <strong className="title default-text-color">Opmerkingen:</strong> {summaryData.extraInfo || 'Geen'}
            </li>
          </ul>
        </div>
      )}

      <div className="action-buttons">
        <PrintButton summaryRef={summaryRef} />
        <ShareButton summaryRef={summaryRef} />
        <AddToCalendarButton summaryData={summaryData} />
      </div>
    </div>
  );
};

export default SuccessMessage;
